import {
  Controller
} from "@hotwired/stimulus"

// Connects to data-controller="galleries"
export default class extends Controller {
  static targets = ["gallery_form_box"]

  connect() {
    if (this.element.dataset.action === "show") {
      var sync_interval = setInterval(() => {
        this.sync_imgs();
        if($('.img_on_process').length == 0){
          clearInterval(sync_interval);
        }
        console.clear();
      }, 5000);
    }
  }

  gallery_form_boxTargetConnected(element) {
    var galleryFormObject = $("#gallery_form")
    var gallery_id = galleryFormObject.data("id");
    var resize_width = galleryFormObject.data("resize-width");
    var resize_quality = galleryFormObject.data("resize-quality");
    var url_gallery = window.location.origin + '/galleries/' + gallery_id + '.json';
    var remove_file_translate = $("#gallery_form").data("remove-translate");
    const fetch_Progress = this.fetchProgress;
    var ready_to_fetch = true;

    var myDropzone = new Dropzone("#gallery_form", {
      url: "/photos",
      method: "post",
      dictRemoveFile: remove_file_translate,
      timeout: 0, // Unlimit TimeOut was set to support big files
      maxThumbnailFilesize: 128,
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      //Resize the photos based on the settings values
      resizeQuality: resize_quality,
      resizeWidth: resize_width,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      paramName: "photo[img]",
      autoProcessQueue: true,
      acceptedFiles: 'image/*',
      parallelUploads: 4,
      autoQueue: true,
      addRemoveLinks: true,
      init: function() {
        let self_dropzone = this;

        self_dropzone.on("sending", function(file, xhr, formData) {
          formData.append("photo[gallery_id]", gallery_id);
          ready_to_fetch = true
        });

        self_dropzone.on("complete", function(file) {
          if (self_dropzone.getUploadingFiles().length === 0 && self_dropzone.getQueuedFiles().length === 0) {
            if (ready_to_fetch) {
              fetch_Progress(url_gallery);
              ready_to_fetch = false
            }else{}
          }
        });

        // self_dropzone.on("uploadprogress", function(file, progress, bytesSent) {
        //   console.log(progress)
        // });

        // If you only have access to the original image sizes on your server,
        // and want to resize them in the browser:
        let callback = null; // Optional callback when it's done
        let crossOrigin = "anonymous"; // Added to the `img` tag for crossOrigin handling
        let resizeThumbnail = true; // Tells Dropzone whether it should resize the image first

        $.ajax({
          type: 'get',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          url: url_gallery,
          dataType: "json",
          success: function(data, textStatus, xhr) {
            data.photos.forEach(function(photo) {
              let mockFile = {
                name: photo.name,
                size: photo.file_size,
                id: photo.id
              };
              self_dropzone.displayExistingFile(mockFile, photo.url_worked, callback, crossOrigin, resizeThumbnail);
            })
          }
        });
      },
      removedfile: function(file) {
        if (file.id) {
          var photo_id = file.id
        } else {
          var jsonResponse = JSON.parse(file.xhr.response);
          var photo_id = jsonResponse["id"];
        }

        var url = window.location.origin + '/photos/' + photo_id;

        $.ajax({
          type: 'delete',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          url: url,
          dataType: "json",
          success: function(data, textStatus, xhr) {
            if (xhr.status == 204) {
              Swal.fire({
                showConfirmButton: false,
                toast: true,
                position: 'top-end',
                html: 'La fotografia ' + file.name + ' fue removida exitosamente',
                icon: 'info',
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              var _ref;
              return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
            } else {
              Swal.fire({
                showConfirmButton: false,
                toast: true,
                position: 'top-end',
                html: data["error_msg"],
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })

            }
          }
          // ,
          // error: function(XMLHttpRequest, textStatus, errorThrown) {
          //   alert("Status: " + textStatus); alert("Error: " + errorThrown);
          // }
        });
      }
    });

  }

  check_all_photos() {
    $(".photo_checkbox").prop('checked', true).change();
    this.count_photos();
  }

  copy_text() {
    var text = $(event.currentTarget).next("span").text();
    navigator.clipboard.writeText(text)

    Swal.fire({
      showConfirmButton: false,
      toast: true,
      position: 'top-end',
      html: 'El ticket: ' + text + ' se a copiado correctamente.',
      icon: 'success',
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }

  count_photos() {
    // $(event.currentTarget)
    var total = 0;
    $(".photo_checkbox").each(function() {
      var checked = $(this).is(":checked");
      if (checked) {
        total += 1
      }
    });
    $("#total_photos_selected").text(total);
    if (total > 0) {
      $(".float-right-selecteds-btn").removeClass('d-none');
    } else {
      $(".float-right-selecteds-btn").addClass('d-none');
    }
  }

  fetchProgress(gallery_url) {
    var final_url = gallery_url || window.location.href.replace("/edit", "") + ".json";
    var interval = setInterval(() => {
      fetch(final_url, {
          method: 'GET',
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
        })
        .then(response => response.json())
        .then(data => {
          const progressBar = document.getElementById("progress-bar-img-process");
          const progress_text = document.getElementById("progress-bar-img-process-text");
          if (progressBar){
            progressBar.style.width = `${data.progress}%`;
          }
          if(progress_text){
            progress_text.innerHTML = `${data.progress}%`;
          }

          if (data.progress == 100) {
            setTimeout(() => {
              $(".progress").hide()
              clearInterval(interval);
            }, 5000);
          } else {
            $(".progress").show();
          }
        })
        .catch(error => {
          console.error('Error fetching progress:', error);
        });
    }, 5000); // Fetch progress every 5 second
  }

  sync_imgs(){
    $('.img_on_process').each(function(){ // selecting all image element on the page
      var original_img = $(this);
      let img = new Image(); // creating image element
      img.src = $(this).data("src");

      img.onload = function() { // trigger if the image was loaded
        original_img.attr('src', original_img.data("src") + '?timestamp=' + new Date().getTime() );
        original_img.addClass("img_loaded");
        original_img.removeClass("img_on_process");
      }
      // img.onerror = function() { // trigger if the image wasn't loaded
        // console.log(img.src + ' - error!');
      // }
      // img.onAbort = function() { // trigger if the image load was abort
        // console.log(img.src + ' - abort!');
      // }
    });
  }

  destroy_photo(event){
    var btn_element = $(event.currentTarget);
    var url = $(btn_element).data("destroy-on");
    var img_name = $(btn_element).data("name");

    $.ajax({
      type: 'delete',
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
      url: url,
      dataType: "json",
      success: function(data, textStatus, xhr) {
        if (xhr.status == 204) {
          Swal.fire({
            showConfirmButton: false,
            toast: true,
            position: 'top-end',
            html: 'La fotografia ' + img_name + ' fue removida exitosamente',
            icon: 'info',
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          $(btn_element).closest('.col-12.col-md-6.col-lg-4.p-0').fadeOut(500, function(){ $(this).remove();});
        } else {
          Swal.fire({
            showConfirmButton: false,
            toast: true,
            position: 'top-end',
            html: data["error_msg"],
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

        }
      }
      // ,
      // error: function(XMLHttpRequest, textStatus, errorThrown) {
      //   alert("Status: " + textStatus); alert("Error: " + errorThrown);
      // }
    });
  }

}
