// Entry point for the build script in your package.json
import './add_jquery'
import "@hotwired/turbo-rails"
import "./controllers"
import "./dropzone-min"
import * as bootstrap from "bootstrap"
import lozad from 'lozad'
import Swal from 'sweetalert2'

window.Swal = Swal;

const observer = lozad('.lozad', {
  loaded: function(el) {
    // Custom implementation on a loaded element
    el.classList.add('img_loaded');
  }
});

$(document).on('turbo:load', function () {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  if ($("body").data("action") != "show" && $("body").data("controller") != "galleries") {
    // Stop all timeout or intervals on page load
    var killId = setTimeout(function() {
      for (var i = killId; i > 0; i--) clearInterval(i)
    }, 5000);
  };

  observer.observe();
});
