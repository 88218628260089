import { Controller } from "@hotwired/stimulus"
import axios from 'axios';

// Connects to data-controller="photo_galleries"
export default class extends Controller {

  connect() {
  }

  force_donwload_img_axios(event){
    var btn_obj = $(event.currentTarget);
    var url_src = btn_obj.attr('href');

    btn_obj.find(">:first-child").toggleClass('bi-cloud-arrow-down bi-arrow-clockwise')
    btn_obj.find(">:first-child").toggleClass('rotate_static rotate_loop')
    event.preventDefault();

    axios({
      url: url_src,
      method:'GET',
      responseType: 'blob',
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
	   }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.download = btn_obj.attr("download");
      link.onclick = function() {
        btn_obj.find(">:first-child").toggleClass('bi-cloud-arrow-down bi-arrow-clockwise');
        btn_obj.find(">:first-child").toggleClass('rotate_static rotate_loop');
      }
      document.body.appendChild(link);
      link.click();
    }).catch(function(error) {
      btn_obj.find(">:first-child").toggleClass('bi-cloud-arrow-down bi-arrow-clockwise');
      btn_obj.find(">:first-child").toggleClass('rotate_static rotate_loop');
      console.log(error)
      event.isDefaultPrevented = function(){ return false; }
      // retrigger with the exactly same event data
      btn_obj.trigger(event);
    });
  }

  async force_donwload_img(event){
    var btn_obj = $(event.currentTarget);
    var url_src = btn_obj.attr('href');

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/*");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var request_conf = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      // cache: 'default'
    };

    try {
      btn_obj.find(">:first-child").toggleClass('bi-cloud-arrow-down bi-arrow-clockwise')
      btn_obj.find(">:first-child").toggleClass('rotate_static rotate_loop')

      event.preventDefault();

      const image = await fetch(url_src, request_conf)
  	  const imageBlog = await image.blob()
  	  const imageURL = URL.createObjectURL(imageBlog)

  	  var link = document.createElement('a')
  	  link.href = imageURL
      link.onclick = function() {
        btn_obj.find(">:first-child").toggleClass('bi-cloud-arrow-down bi-arrow-clockwise');
        btn_obj.find(">:first-child").toggleClass('rotate_static rotate_loop');
      }
  	  link.download = url_src.split('/').pop();
  	  document.body.appendChild(link)
  	  link.click()
  	  document.body.removeChild(link)
    } catch (error) {
      console.log(error)
      event.isDefaultPrevented = function(){ return false; }
      // retrigger with the exactly same event data
      btn_obj.trigger(event);
    }
  }

  search_coupon(event){
    var input_text_code = $(event.currentTarget);
    var url_search_coupon = input_text_code.data("target-url");
    var search_text = input_text_code.val();
    input_text_code.removeClass("is-invalid is-valid");
    if (search_text.length > 2){

      $.ajax({
        url: url_search_coupon + "?code=" + search_text,
      }).done(function( data ) {
        if (Object.keys(data).length > 0){
          var currency_text = document.documentElement.lang == 'en' ? 'USD' : 'MXN'
          input_text_code.addClass("is-valid").removeClass("is-invalid");
          var original_price = parseFloat($("#price_list").data("price-list"));
          var discount_amount = document.documentElement.lang == 'en' ? data["amount_usd"] : data["amount_mxn"]
          $(".coupon_code").html(data["code"]);

          if (data["type_of"] == "amount"){
            $(".coupon_amount").html('-' + parseFloat(discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '+ currency_text);
            $("#payment_order_total").html((original_price - discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '+ currency_text);
          }else{
            $(".coupon_amount").html('-' + discount_amount + ' %');
            var discount_percent = parseFloat(discount_amount)/100;
            var total_discount = discount_percent * original_price;
            $("#payment_order_total").html((original_price - total_discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' '+ currency_text);
          }
          $(".coupon_info").removeClass("d-none");

        }else{
          input_text_code.removeClass("is-valid").addClass("is-invalid");
          $(".coupon_info").addClass("d-none");
        }
      });
    }
  }

  closePrint() {
    document.body.removeChild(this.__container__);
  }

  setPrint() {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = this.closePrint;
    this.contentWindow.onafterprint = this.closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
  }

  printPage(event){
    event.preventDefault();
    var url_pdf = $(event.currentTarget).data("target-url");
    const hideFrame = document.createElement("iframe");
    hideFrame.onload = this.setPrint;
    hideFrame.style.position = "fixed";
    hideFrame.style.right = "0";
    hideFrame.style.bottom = "0";
    hideFrame.style.width = "0";
    hideFrame.style.height = "0";
    hideFrame.style.border = "0";
    hideFrame.src = url_pdf;
    document.body.appendChild(hideFrame);

  }

  print_on_firefox() {
    event.preventDefault();
    var url_pdf = $(event.currentTarget).data("target-url");

    var iframe = this._printIframe;
    if (!this._printIframe) {
      iframe = this._printIframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
    }

    iframe.src = url_pdf;
  }

  chosen_payment_platform(event){
    var seleceted_value = event.target.value;

    if (seleceted_value  == 'system_card'){
      $("#add_cash_to_payment").removeClass("d-none").addClass("d-block");
      $("#cash_payment").removeClass("d-block").addClass("d-none");
    }else if (seleceted_value  == 'system_cash'){
      $("#add_cash_to_payment").removeClass("d-none").addClass("d-block");
      $("#cash_payment").removeClass("d-none").addClass("d-block");
      $("#payment_order_amount").val(null);
      $("#payment_order_amount, .cash_currency").prop("disabled", false);
    }else{
      $("#add_cash_to_payment, #cash_payment").removeClass("d-block").addClass("d-none")
      $("#payment_order_amount, .cash_currency").prop("disabled", true);
      $("#payment_order_extra_cash").val(null);
    }
  }

  copy_text() {
    var text = $(event.currentTarget).next("span").text();
    navigator.clipboard.writeText(text)

    Swal.fire({
      showConfirmButton: false,
      toast: true,
      position: 'top-end',
      html: 'El ticket: ' + text + ' se a copiado correctamente.',
      icon: 'success',
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
  }
}
