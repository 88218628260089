import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-messages"
export default class extends Controller {
  connect() {
    $(".flash_div").each(function( index ) {
      var flash_type = $( this ).data("type");
      var flash_msg = $( this ).data("msg");

      Swal.fire({
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
        html: flash_msg,
        icon: flash_type,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    });

  }

}
